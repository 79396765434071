.btn {
  font-family: 'Inter';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 22px 25px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  min-width: 200px;
  color: var(--light-color);
  border-radius: 10px;
  background-color: var(--btn-color);
  transition: all .3s ease;
  &:hover, &:active, &:focus {
    color: var(--light-color);
    background-color: var(--btn-hover);
  }
  @include mobile {
    width: 100%;
    padding: 18px 25px;
  }
}
.form__btn {
  padding: 16px 25px;
  max-height: 48px;
  @include small-tablet {
    width: 100%;
  }
}
.btn__center {
  text-align: center;
}

.slide__btn {
  stroke: var(--dark-color);
  @include mobile {
    stroke: var(--light-color);
    height: 23px;
  }
}
