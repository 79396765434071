.awards {
  padding-bottom: 165px;
  @include mobile {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include tablet {
    padding-bottom: 60px;
  }
  &__title {
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 0;
    }
    @include mobile {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 132%;
    color: var(--btn-color);
    text-align: center;
  }
  &__img {
    margin-bottom: 15px;
    img {
      max-width: fit-content;
      @include tablet {
        max-width: 100%;
      }
    }

  }
  &__item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 435px;
  }
  &__slider {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      bottom: 111px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: var(--btn-color);
    }
  }
}
.awards__img-1 {
  transform: translateX(-30px);
  @include tablet {
    transform: translateX(0);
  }
}
.awards__img-2 {
  transform: translateX(-14px);
  @include mobile {
    transform: translateX(0);
  }
}
.awards__img-4 {
  transform: translateX(15px);
  @include mobile {
    transform: translateX(30px);
  }
}

.awards__img-5 {
  transform: translateX(-15px);
  @include mobile {
    transform: translateX(0);
  }
}

.awards__img-6 {
  transform: translateX(-15px);
  @include mobile {
    transform: translateX(0);
  }
}
