:root {
  --font-family: "WorkSans", sans-serif;
  --content-width: 1120px;
  --content-fluid-width: 1300px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));
  --light-color: #ffffff;
  --dark-color: #000000;
  --btn-color: #3A55F7;
  --btn-hover: #1937EF;
  --green-color: #20A354;
  --accent-color: #55D086;
}

/* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansMediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  color: var(--dark-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

html {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

b, strong {
  font-weight: 700;
}

a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}

a, li, button {
  outline: none !important;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  font-family: "Inter";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 22px 25px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  min-width: 200px;
  color: var(--light-color);
  border-radius: 10px;
  background-color: var(--btn-color);
  transition: all 0.3s ease;
}
.btn:hover, .btn:active, .btn:focus {
  color: var(--light-color);
  background-color: var(--btn-hover);
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
    padding: 18px 25px;
  }
}

.form__btn {
  padding: 16px 25px;
  max-height: 48px;
}
@media (max-width: 768px) {
  .form__btn {
    width: 100%;
  }
}

.btn__center {
  text-align: center;
}

.slide__btn {
  stroke: var(--dark-color);
}
@media (max-width: 576px) {
  .slide__btn {
    stroke: var(--light-color);
    height: 23px;
  }
}

.title {
  font-weight: 500;
  font-size: 80px;
  line-height: 70px;
}
.title-blue {
  color: var(--btn-color);
}
@media (max-width: 768px) {
  .title {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
}

.header {
  padding: 30px 0 60px;
}
@media (max-width: 576px) {
  .header {
    padding: 15px 0 30px;
  }
}

.logo {
  display: inline-block;
}
@media (max-width: 576px) {
  .logo {
    width: 120px;
  }
}

.intro__wrap {
  position: relative;
}
@media (max-width: 768px) {
  .intro__wrap {
    margin-bottom: 60px;
  }
}
.intro__title {
  font-weight: 600;
  font-size: 76px;
  line-height: 106.3%;
  max-width: 808px;
  margin-bottom: 45px;
}
.intro__title span {
  color: var(--btn-color);
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .intro__title {
    font-weight: 600;
    font-size: 48px;
    line-height: 106.3%;
  }
}
@media (max-width: 576px) {
  .intro__title {
    margin-bottom: 400px;
  }
}
.intro__img {
  width: 600px;
  height: 606px;
  background-image: url(../img/phone-desk.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-right: 0;
  margin-left: auto;
  margin-top: -165px;
}
@media (max-width: 1200px) {
  .intro__img {
    transform: translateX(200px);
  }
}
@media (max-width: 850px) {
  .intro__img {
    transform: translateX(300px);
  }
}
@media (max-width: 576px) {
  .intro__img {
    background-image: url(../img/phone-mob.png);
    max-width: 370px;
    max-height: 438px;
    margin: 0 auto;
    margin-top: 0;
    transform: translateX(0);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
  }
}
.intro__line {
  position: absolute;
  content: "";
  left: 15px;
  top: 50%;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #4555F2;
  z-index: 1;
}
.intro__line::after {
  position: absolute;
  content: "";
  top: 18px;
  left: 0;
  width: 38px;
  height: 238px;
  background: linear-gradient(180deg, #3A55F7 0%, rgba(217, 217, 217, 0) 100%);
  z-index: 0;
}
@media (max-width: 576px) {
  .intro__line {
    display: none;
  }
}
@media (max-width: 576px) {
  .intro__btn {
    text-align: center;
  }
}
.intro__coin {
  position: absolute;
  top: 25%;
  left: 30%;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .intro__coin {
    top: 6%;
    left: 91%;
  }
}
.intro__circle {
  position: absolute;
  bottom: 20%;
  left: 30%;
}
@media (max-width: 768px) {
  .intro__circle {
    display: none;
  }
}

.ecosystem {
  margin-top: -35px;
  padding-bottom: 190px;
}
@media (max-width: 576px) {
  .ecosystem {
    margin-top: 0;
    padding-bottom: 60px;
  }
}
.ecosystem__wrap {
  position: relative;
}
.ecosystem__content {
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .ecosystem__content {
    margin-bottom: 30px;
  }
}
.ecosystem__coin {
  position: absolute;
  top: 20%;
  right: 14%;
  transform: rotate(20deg);
}
@media (max-width: 768px) {
  .ecosystem__coin {
    display: none;
  }
}
.ecosystem__title {
  margin-bottom: 25px;
  max-width: 550px;
}
@media (max-width: 768px) {
  .ecosystem__title {
    margin-bottom: 15px;
  }
}
.ecosystem__text {
  max-width: 550px;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
}
.ecosystem__text span {
  color: var(--btn-color);
  font-weight: 600;
}
@media (max-width: 768px) {
  .ecosystem__text {
    font-size: 20px;
    line-height: 140%;
  }
}
.ecosystem__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 74px 24px;
  max-width: 830px;
  margin-left: auto;
  margin-right: 0;
}
@media (max-width: 576px) {
  .ecosystem__grid {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0 25px 0 40px;
  }
}
.ecosystem__number {
  font-family: "Inter";
  font-weight: 700;
  font-size: 72px;
  line-height: 125%;
  color: var(--accent-color);
}
@media (max-width: 768px) {
  .ecosystem__number {
    line-height: 95%;
  }
}
.ecosystem__desc {
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  max-width: 260px;
  color: var(--green-color);
}
@media (max-width: 768px) {
  .ecosystem__desc {
    font-size: 18px;
    line-height: 140%;
  }
}
@media (max-width: 576px) {
  .ecosystem__desc {
    max-width: 170px;
  }
}
.ecosystem__icon {
  min-height: 68px;
  margin-bottom: 15px;
}
.ecosystem__item:nth-child(2) {
  grid-column: 2/3;
}
.ecosystem__item:nth-child(3) {
  grid-column: 1/2;
}
.ecosystem__item:nth-child(5) {
  transform: translateY(-28px);
}
.ecosystem__item:nth-child(5) .ecosystem__desc {
  max-width: 150px;
}
@media (max-width: 576px) {
  .ecosystem__item:nth-child(2) {
    grid-column: 1/1;
    margin-right: 0;
    margin-left: auto;
    margin-top: 20px;
  }
  .ecosystem__item:nth-child(3) {
    grid-column: 1/1;
    margin-left: auto;
    margin-right: 0;
  }
  .ecosystem__item:nth-child(4) .ecosystem__desc {
    max-width: 100%;
  }
  .ecosystem__item:nth-child(5) {
    grid-row: 3/4;
    transform: translateY(-15px);
  }
}

.description {
  padding-bottom: 190px;
}
.description__text {
  font-weight: 400;
  font-size: 45px;
  line-height: 118%;
  max-width: 997px;
  margin: 0 auto;
  text-align: center;
}
.description__text span {
  font-weight: 600;
  color: var(--btn-color);
}
@media (max-width: 768px) {
  .description__text {
    font-size: 28px;
  }
}
@media (max-width: 576px) {
  .description__text {
    text-align: left;
  }
  .description__text:nth-child(1) {
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .description {
    padding-bottom: 60px;
  }
}

.trading {
  padding-top: 100px;
  padding-bottom: 120px;
  background-color: #000000;
  color: #ffffff;
}
@media (max-width: 576px) {
  .trading {
    padding-top: 50px;
    padding-bottom: 0px;
  }
}
.trading__title {
  margin-bottom: 140px;
  max-width: 840px;
}
.trading__title span {
  color: var(--accent-color);
}
@media (max-width: 576px) {
  .trading__title {
    margin-bottom: 30px;
  }
}
.trading__content {
  padding: calc((100vw - 930px) / 2);
  padding-top: 0;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 25px;
  align-items: start;
}
@media (max-width: 1024px) {
  .trading__content {
    padding: 0 15px;
  }
}
@media (max-width: 768px) {
  .trading__content {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
.trading__form {
  background: linear-gradient(180deg, #55D086 0%, rgba(85, 208, 134, 0) 100%);
  border-radius: 20px;
  padding: 44px 95px 100px;
  max-width: 930px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .trading__form {
    padding: 50px 15px 65px;
  }
}

.form {
  padding-top: 210px;
}
@media (max-width: 576px) {
  .form {
    padding-top: 60px;
  }
}

.card__icon {
  margin-bottom: 25px;
}
.card__title {
  margin-bottom: 20px;
  color: var(--accent-color);
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
}
.card__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  max-width: 390px;
}
@media (max-width: 576px) {
  .card__text {
    font-size: 20px;
  }
}
.card__wrap {
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 25px 28px;
  border: 1px solid transparent;
  background: linear-gradient(#161616 0 0) padding-box, linear-gradient(to right, rgb(146, 143, 143), transparent) border-box;
}
.card__left {
  margin-left: 54px;
}
@media (max-width: 768px) {
  .card__left {
    margin-left: 30px;
  }
}
.card__left-top {
  margin-top: 100px;
  margin-bottom: 90px;
}
@media (max-width: 768px) {
  .card__left-top {
    grid-row: 2/3;
  }
}
@media (max-width: 576px) {
  .card__left-top {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}
.card__right-midl {
  margin-left: 35px;
  margin-bottom: 110px;
}
@media (max-width: 768px) {
  .card__right-midl {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  .card__right-midl .card__icon {
    text-align: right;
    margin-right: 60px;
  }
}
@media (max-width: 576px) {
  .card__right-midl .card__icon {
    margin-bottom: 0;
  }
}
.card__left-midl {
  margin-top: 20px;
}
.card__right-bot {
  margin-top: 73px;
}
@media (max-width: 768px) {
  .card__right-bot {
    grid-row: 5/6;
    margin-top: 0;
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .card__left-bot {
    grid-row: 6/7;
  }
}
@media (max-width: 768px) {
  .card__right-top {
    grid-row: 1/2;
  }
}
.card__right-bg {
  position: relative;
}
.card__right-bg::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 8px;
  width: 720px;
  height: 380px;
  transform-origin: top left;
  background-image: url(../img/card-right.svg);
  background-repeat: no-repeat;
  background-size: 128%;
}
@media (max-width: 576px) {
  .card__right-bg::before {
    top: 15px;
    left: 3px;
  }
}
.card__right-two::before {
  top: 75px;
}
@media (max-width: 768px) {
  .card__right-two::before {
    top: 21px;
    width: 524px;
  }
}
@media (max-width: 576px) {
  .card__right-two::before {
    top: 21px;
    width: 720px;
  }
}
.card__left-bg {
  position: relative;
}
.card__left-bg::before {
  position: absolute;
  content: "";
  top: 21px;
  right: -105px;
  width: 1011px;
  height: 441px;
  background-image: url(../img/card-left.svg);
  background-repeat: no-repeat;
  background-size: 84%;
}
@media (max-width: 576px) {
  .card__left-bg::before {
    top: 30px;
    right: -156px;
  }
}
.card__left-bg .card__wrap {
  background: linear-gradient(#161616 0 0) padding-box, linear-gradient(to left, rgb(146, 143, 143), transparent) border-box;
}

.form__title {
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  max-width: 580px;
  margin: 0 auto;
  margin-bottom: 55px;
}
.form__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .form__wrap {
    flex-direction: column;
  }
}
.form__label {
  position: relative;
  width: 100%;
  margin-right: 12px;
}
@media (max-width: 768px) {
  .form__label {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.form__input {
  width: 100%;
  height: 48px;
  background: #F4F4F9;
  border-radius: 10px;
  color: var(--dark-color);
  padding: 0 20px;
}
.form__input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #A3AFB9;
}

.just-validate-error-label {
  position: absolute;
  bottom: 50px;
  left: 0;
  color: var(--light-color) !important;
}
@media (max-width: 768px) {
  .just-validate-error-label {
    font-size: 12px;
  }
}

.choose {
  padding: 100px 0;
}
@media (max-width: 576px) {
  .choose {
    padding: 50px 0;
  }
}
.choose__title {
  margin-bottom: 25px;
}
.choose__title span {
  color: var(--green-color);
}
@media (max-width: 576px) {
  .choose__title {
    margin-bottom: 15px;
  }
}
.choose__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 43px;
}
@media (max-width: 576px) {
  .choose__text {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
.choose__content {
  position: relative;
  text-align: center;
}
.choose__word {
  color: var(--accent-color);
  font-weight: 500;
  font-size: 247px;
  line-height: 290px;
  letter-spacing: 0.24em;
  text-align: center;
}
.choose__word span {
  font-style: italic;
}

.slogan {
  position: absolute;
  top: 50%;
  transform: translateY(9px);
  left: 67px;
  background-color: var(--light-color);
  height: 26px;
}
.slogan__item {
  position: relative;
  padding-left: 35px;
  margin-left: 5px;
  font-weight: 400;
  font-size: 32px;
  line-height: 25px;
  color: var(--btn-color);
}
.slogan__item b {
  font-weight: 600;
}
.slogan__item::after {
  position: absolute;
  content: "";
  left: 0;
  top: 9px;
  width: 25px;
  height: 22px;
  background-color: var(--accent-color);
}

.awards {
  padding-bottom: 165px;
}
@media (max-width: 576px) {
  .awards .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1024px) {
  .awards {
    padding-bottom: 60px;
  }
}
.awards__title {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .awards__title {
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .awards__title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.awards__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 132%;
  color: var(--btn-color);
  text-align: center;
}
.awards__img {
  margin-bottom: 15px;
}
.awards__img img {
  max-width: fit-content;
}
@media (max-width: 1024px) {
  .awards__img img {
    max-width: 100%;
  }
}
.awards__item {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  min-height: 435px;
}
.awards__slider {
  position: relative;
}
.awards__slider::after {
  position: absolute;
  content: "";
  bottom: 111px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--btn-color);
}

.awards__img-1 {
  transform: translateX(-30px);
}
@media (max-width: 1024px) {
  .awards__img-1 {
    transform: translateX(0);
  }
}

.awards__img-2 {
  transform: translateX(-14px);
}
@media (max-width: 576px) {
  .awards__img-2 {
    transform: translateX(0);
  }
}

.awards__img-4 {
  transform: translateX(15px);
}
@media (max-width: 576px) {
  .awards__img-4 {
    transform: translateX(30px);
  }
}

.awards__img-5 {
  transform: translateX(-15px);
}
@media (max-width: 576px) {
  .awards__img-5 {
    transform: translateX(0);
  }
}

.awards__img-6 {
  transform: translateX(-15px);
}
@media (max-width: 576px) {
  .awards__img-6 {
    transform: translateX(0);
  }
}

.team {
  padding-bottom: 160px;
}
@media (max-width: 768px) {
  .team {
    padding-bottom: 100px;
  }
}
@media (max-width: 576px) {
  .team {
    padding-bottom: 60px;
  }
}
.team__title {
  margin-bottom: 20px;
  max-width: 950px;
}
.team__title span {
  color: var(--green-color);
}
.team__item {
  transform: scale(1);
  transition: transform 0.2s ease;
  max-width: 260px;
}
@media (max-width: 576px) {
  .team__item {
    height: 250px;
    max-width: 100%;
  }
}
.team__slider {
  padding: 145px 0 160px;
}
@media (max-width: 768px) {
  .team__slider {
    padding: 100px 0 60px;
  }
}
@media (max-width: 576px) {
  .team__slider {
    padding: 30px 0 60px;
  }
}
.team .swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 576px) {
  .team .swiper-slide img {
    height: 100%;
  }
}

.swiper-slide-active .team__item {
  transform: scale(1.7);
  transition: transform 0.2s ease;
  position: relative;
}
.swiper-slide-active .team__item::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  background: #55D086;
  mix-blend-mode: multiply;
  opacity: 0;
  transition: all 0.3s ease;
}
@media (max-width: 992px) {
  .swiper-slide-active .team__item::after {
    display: none;
  }
}
@media (max-width: 1200px) {
  .swiper-slide-active .team__item {
    transform: scale(1.3);
  }
}
@media (max-width: 768px) {
  .swiper-slide-active .team__item {
    transform: scale(1);
  }
}

.swiper-slide-active .team__item:hover::after {
  opacity: 0.7;
}

.swiper-button-prev::after, .swiper-button-next::after {
  display: none;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0;
}

.site-container {
  background-image: url(../img/team-bg.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto;
}

.footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  padding-bottom: 25px;
}
.footer__wrap {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .footer__wrap {
    flex-direction: column-reverse;
  }
}
@media (max-width: 576px) {
  .footer__link {
    margin-bottom: 15px;
  }
}

.copyright {
  color: #999999;
}

.link {
  color: var(--btn-color);
  margin-right: 40px;
}
.link:last-child {
  margin-right: 0;
}