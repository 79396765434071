.footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  padding-bottom: 25px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column-reverse;
    }
  }
  &__link {
    @include mobile {
      margin-bottom: 15px;
    }
  }
}

.copyright {
  color: #999999;
}
.link {
  color: var(--btn-color);
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
}
