.trading {
  padding-top: 100px;
  padding-bottom: 120px;
  background-color: #000000;
  color: #ffffff;
  @include mobile {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  &__title {
    margin-bottom: 140px;
    max-width: 840px;
    span {
      color: var(--accent-color);
    }
    @include mobile {
      margin-bottom: 30px;
    }
  }
  &__content {
    padding: calc((100vw - 930px) / 2);
    padding-top: 0;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 25px;
    align-items: start;

    @include tablet {
      padding: 0 15px;
    }
    @include small-tablet {
      grid-template-columns: 1fr;
      gap: 0 ;
    }

  }

  &__form {
    background: linear-gradient(180deg, #55D086 0%, rgba(85, 208, 134, 0) 100%);
    border-radius: 20px;
    padding: 44px 95px 100px;
    max-width: 930px;
    margin: 0 auto;
    @include mobile {
      padding: 50px 15px 65px;
    }
  }
}

.form {
  padding-top: 210px;
  @include mobile {
    padding-top: 60px;
  }
}

.card {
  &__icon {
    margin-bottom: 25px;
  }
  &__title {
    margin-bottom: 20px;
    color: var(--accent-color);
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
  }
  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    max-width: 390px;
    @include mobile {
      font-size: 20px;
    }
  }
  &__wrap {
    position: relative;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 25px 28px;
    border: 1px solid transparent;
    background:
    linear-gradient(#161616 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to right, rgb(146, 143, 143), transparent) border-box;
  }
  &__left {
    margin-left: 54px;
    @include small-tablet {
      margin-left: 30px;
    }
  }
  &__left-top {
    margin-top: 100px;
    margin-bottom: 90px;
    @include small-tablet {
      grid-row: 2 / 3;
    }
    @include mobile {
      margin-top: 50px;
      margin-bottom: 60px;
    }
  }
  &__right-midl {
    margin-left: 35px;
    margin-bottom: 110px;
    @include small-tablet {
      margin-top: 80px;
      margin-bottom: 50px;
      .card__icon {
        text-align: right;
        margin-right: 60px;
      }
    }
    @include mobile {
      .card__icon {
        margin-bottom: 0;
      }
    }
  }
  &__left-midl {
    margin-top: 20px;
  }
  &__right-bot {
    margin-top: 73px;
    @include small-tablet {
      grid-row: 5 / 6;
      margin-top: 0;
      margin-bottom: 80px;
    }
  }
  &__left-bot {
    @include small-tablet {
      grid-row: 6 / 7;
    }
  }
  &__right-top {
    @include small-tablet {
      grid-row: 1 / 2;
    }
  }
  &__right-bg {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 2px;
      left: 8px;
      width: 720px;
      height: 380px;
      transform-origin: top left;
      background-image: url(../img/card-right.svg);
      background-repeat: no-repeat;
      background-size: 128%;
      @include mobile {
        top: 15px;
        left: 3px;
      }
    }
  }

  &__right-two {
    &::before {
      top: 75px;
      @include small-tablet {
        top: 21px;
        width: 524px;
      }
      @include mobile {
        top: 21px;
        width: 720px;
      }
    }
  }
  &__left-bg {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 21px;
      right: -105px;
      width: 1011px;
      height: 441px;
      background-image: url(../img/card-left.svg);
      background-repeat: no-repeat;
      background-size: 84%;
      @include mobile {
        top: 30px;
        right: -156px;
      }
    }
    .card__wrap {
      background:
      linear-gradient(#161616 0 0) padding-box, /*this is your grey background*/
      linear-gradient(to left, rgb(146, 143, 143), transparent) border-box;
    }
  }
}


.form {
  &__title {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    max-width: 580px;
    margin: 0 auto;
    margin-bottom: 55px;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include small-tablet {
      flex-direction: column;
    }
  }
  &__label {
    position: relative;
    width: 100%;
    margin-right: 12px;
    @include small-tablet {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  &__input {
    width: 100%;
    height: 48px;
    background: #F4F4F9;
    border-radius: 10px;
    color: var(--dark-color);
    padding: 0 20px;
    &::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #A3AFB9;
    }
  }
}

.just-validate-error-label {
  position: absolute;
  bottom: 50px;
  left: 0;
  color: var(--light-color)!important;
  @include small-tablet {
    font-size: 12px;
  }
}
