.team {
  padding-bottom: 160px;
  @include small-tablet {
    padding-bottom: 100px;
  }
  @include mobile {
    padding-bottom: 60px;
  }
  &__title {
    margin-bottom: 20px;
    max-width: 950px;
    span {
      color: var(--green-color);
    }
  }
  &__item {
    transform: scale(1);
    transition: transform .2s ease;
    max-width: 260px;
    @include mobile {
      height: 250px;
      max-width: 100%;
    }
  }
  &__slider {
    padding: 145px 0 160px;
    @include small-tablet {
      padding: 100px 0 60px;
    }
    @include mobile {
      padding: 30px 0 60px;
    }
  }
  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      img {
        height: 100%;
      }
    }
  }
}


.swiper-slide-active .team__item {
  transform: scale(1.7);
  transition: transform .2s ease;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    background: #55D086;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all .3s ease;
    @include high-tablet {
      display: none;
    }
  }
  @include high-desktop {
    transform: scale(1.3);
  }
  @include small-tablet {
    transform: scale(1);
  }
}

.swiper-slide-active .team__item:hover::after {
  opacity: 0.7;
}


.swiper-button-prev, .swiper-button-next {
  &::after {
    display: none;
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0;
}

.site-container {
  background-image: url(../img/team-bg.png);
  background-position: center bottom ;
  background-repeat: no-repeat;
  background-size: auto;
}

