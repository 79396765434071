.header {
  padding: 30px 0 60px;
  @include mobile {
    padding: 15px 0 30px;
  }
}
.logo {
  display: inline-block;
  @include mobile {
    width: 120px;
  }
}
