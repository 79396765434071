.intro {
  &__wrap {
    position: relative;
    @include small-tablet {
      margin-bottom: 60px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 76px;
    line-height: 106.3%;
    max-width: 808px;
    margin-bottom: 45px;
    span {
      color: var(--btn-color);
      text-transform: uppercase;
    }
    @include small-tablet {
      font-weight: 600;
      font-size: 48px;
      line-height: 106.3%;
    }
    @include mobile {
      margin-bottom: 400px;
    }
  }
  &__img {
    width: 600px;
    height: 606px;
    background-image: url(../img/phone-desk.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin-right: 0;
    margin-left: auto;
    margin-top: -165px;

    @include high-desktop {
      transform: translateX(200px);
    }
    @include mini-tablet {
      transform: translateX(300px);
    }
    @include mobile{
      background-image: url(../img/phone-mob.png);
      max-width: 370px;
      max-height: 438px;
      margin: 0 auto;
      margin-top: 0;
      transform: translateX(0);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-size: cover;
    }

  }
  &__line {
    position: absolute;
    content: '';
    left: 15px;
    top: 50%;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #4555F2;
    z-index: 1;
    &::after {
      position: absolute;
      content: '';
      top: 18px;
      left: 0;
      width: 38px;
      height: 238px;
      background: linear-gradient(180deg, #3A55F7 0%, rgba(217, 217, 217, 0) 100%);
      z-index: 0;
    }
    @include mobile {
      display: none;
    }
  }
  &__btn {
    @include mobile {
      text-align: center;
    }
  }

  &__coin {
    position: absolute;
    top: 25%;
    left: 30%;
    transform: rotate(-45deg);
    @include mobile {
      top: 6%;
      left: 91%;
    }
  }
  &__circle {
    position: absolute;
    bottom: 20%;
    left: 30%;
    @include small-tablet {
      display: none;
    }
  }
}
