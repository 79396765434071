.description {
  padding-bottom: 190px;
  &__text {
    font-weight: 400;
    font-size: 45px;
    line-height: 118%;
    max-width: 997px;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 600;
      color: var(--btn-color);
    }
    @include small-tablet {
      font-size: 28px;
    }
    @include mobile {
      &:nth-child(1) {
        margin-bottom: 30px;
      }
      text-align: left;
    }
  }
  @include small-tablet {
    padding-bottom: 60px;
  }
}
