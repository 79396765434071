.title {
  font-weight: 500;
  font-size: 80px;
  line-height: 70px;
  &-blue {
    color: var(--btn-color);
  }
  @include small-tablet {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
}
