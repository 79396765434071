.choose {
  padding: 100px 0;
  @include mobile {
    padding: 50px 0;
  }
  &__title {
    margin-bottom: 25px;
    span {
      color: var(--green-color)
    }
    @include mobile {
      margin-bottom: 15px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 43px;
    @include mobile {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
  &__content {
    position: relative;
    text-align: center;
  }
  &__word {
    color: var(--accent-color);
    font-weight: 500;
    font-size: 247px;
    line-height: 290px;
    letter-spacing: 0.24em;
    text-align: center;
    span {
      font-style: italic;
    }
  }
}

.slogan {
  position: absolute;
  top: 50%;
  transform: translateY(9px);
  left: 67px;
  background-color: var(--light-color);
  height: 26px;
  &__item {
    position: relative;
    padding-left: 35px;
    margin-left: 5px;
    font-weight: 400;
    font-size: 32px;
    line-height: 25px;
    color: var(--btn-color);
    b {
      font-weight: 600;
    }
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 9px;
      width: 25px;
      height: 22px;
      background-color: var(--accent-color);
    }
  }
}
