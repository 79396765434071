.ecosystem {
  margin-top: -35px;
  padding-bottom: 190px;
  @include mobile {
    margin-top: 0;
    padding-bottom: 60px;
  }
  &__wrap {
    position: relative;
  }
  &__content {
    margin-bottom: 50px;
    @include small-tablet {
      margin-bottom: 30px;
    }
  }
  &__coin {
    position: absolute;
    top: 20%;
    right: 14%;
    transform: rotate(20deg);
    @include small-tablet {
      display: none;
    }
  }
  &__title {
    margin-bottom: 25px;
    max-width: 550px;
    @include small-tablet {
      margin-bottom: 15px;
    }
  }
  &__text {
    max-width: 550px;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    span {
      color: var(--btn-color);
      font-weight: 600;
    }
    @include small-tablet {
      font-size: 20px;
      line-height: 140%;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 74px 24px;
    max-width: 830px;
    margin-left: auto;
    margin-right: 0;
    @include mobile {
      grid-template-columns: 1fr;
      gap: 0;
      padding: 0 25px 0 40px;
    }
  }
  &__number {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 72px;
    line-height: 125%;
    color: var(--accent-color);
    @include small-tablet {
      line-height: 95%;
    }
  }
  &__desc {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    max-width: 260px;
    color: var(--green-color);
    @include small-tablet {
      font-size: 18px;
      line-height: 140%;
    }
    @include mobile {
      max-width: 170px;
    }
  }

  &__icon {
    min-height: 68px;
    margin-bottom: 15px;
  }
  &__item {
    &:nth-child(2) {
      grid-column: 2 / 3;
    }
    &:nth-child(3) {
      grid-column: 1 / 2;
    }
    &:nth-child(5) {
      transform: translateY(-28px);
      .ecosystem__desc {
        max-width: 150px;
      }
    }
    @include mobile {
      &:nth-child(2) {
        grid-column: 1 / 1;
        margin-right: 0;
        margin-left: auto;
        margin-top: 20px;
      }
      &:nth-child(3) {
        grid-column: 1 / 1;
        margin-left: auto;
        margin-right: 0;
      }
      &:nth-child(4) {
        .ecosystem__desc {
          max-width: 100%;
        }
      }
      &:nth-child(5) {
        grid-row: 3 / 4;
        transform: translateY(-15px);
      }
    }
  }
}
